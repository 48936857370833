export const languages = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  hi: "हिन्दी",
  ko: "한국어",
  "pt-br": "Português",
  zh: "中文",
};
