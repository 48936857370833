/* Do not edit this file directly */
  import {
  BoxComponent,
  TypeComponent,
  forwardRefWithAs,
  PropsWithAs,
  BaseBoxProps,
  BaseTypeProps,
} from "@tone-row/slang";

type Breakpoints = "small" | "tablet" | "desktop";
type Colors = 
  | "color-background"
  | "color-backgroundHsl"
  | "color-foreground"
  | "color-foregroundHsl"
  | "color-lineColor"
  | "color-uiAccent"
  | "color-nodeHover"
  | "color-edgeHover"
  | "color-lineNumbers"
  | "color-buttonFocus"
  | "color-highlightColor"
  | "color-overlayColor"
  | "color-input"
  | "color-brandHsl"
  | "color-darkHighlight"
  | "color-headerBtnHover"
  | "color-greenHsl"
  | "color-darkGrey"
  | "color-yellowHsl"
  | "palette-white-0"
  | "palette-white-1"
  | "palette-white-2"
  | "palette-white-3"
  | "palette-white-4"
  | "palette-white-5"
  | "palette-white-6"
  | "palette-black-0"
  | "palette-black-1"
  | "palette-black-2"
  | "palette-black-3"
  | "palette-purple-0"
  | "palette-purple-1"
  | "palette-purple-2"
  | "palette-purple-3"
  | "palette-purple-4"
  | "palette-purple-5"
  | "palette-green-0"
  | "palette-green-1"
  | "palette-green-2"
  | "palette-blue-0"
  | "palette-yellow-0"
  | "palette-yellow-1"
  | "palette-yellow-2"
  | "palette-orange-0"
  | "palette-orange-1";
export type BoxProps = PropsWithAs<BaseBoxProps<Breakpoints, Colors>>;
export type TypeProps = PropsWithAs<BaseTypeProps<Breakpoints, Colors>>;
const Box = forwardRefWithAs<BoxProps, "div">(BoxComponent);
const Type = forwardRefWithAs<TypeProps, "p">(TypeComponent);
export { Box, Type };
